import {FC, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {useTheme} from 'styled-components';

import {
  EmailString,
  HoobiizWhiteLabeling,
  HoobiizWhiteLabelingAuthType,
} from '@shared/dynamo_model';
import {EnrichedHoobiizMedia} from '@shared/lib/hoobiiz/hoobiiz_white_labeling';
import {DeepPartial} from '@shared/lib/type_utils';

import {sharedApiCall} from '@shared-frontend/api';
import {ResetPasswordPageModeDefault} from '@shared-frontend/components/auth/mode_default/reset_password_page_theme_0';
import {ModeHeroAuthWhiteLabeling} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {ResetPasswordPageModeHero} from '@shared-frontend/components/auth/mode_hero/reset_password_page_mode_hero';
import {notifyError} from '@shared-frontend/lib/notification';
import {ComponentClass} from '@shared-frontend/lib/react';
import {setSession, useSession} from '@shared-frontend/lib/session_store';
import {useParams} from '@shared-frontend/lib/use_typed_params';

interface ResetPasswordPageProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  whiteLabeling: EnrichedHoobiizMedia<DeepPartial<HoobiizWhiteLabeling>> | undefined;
}

export const ResetPasswordPage: FC<ResetPasswordPageProps> = props => {
  const {wrapper, whiteLabeling} = props;
  const {id} = useParams<{id: string}>();
  const {
    main: {api},
    auth: {postLoginPage},
  } = useTheme();
  const navigate = useNavigate();
  const session = useSession();

  const [email, setEmail] = useState('' as EmailString);
  const [password, setPassword] = useState<string>('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePasswordResetSubmit = useCallback(
    (email: EmailString, password: string) => {
      setIsSubmitting(true);
      if (id === undefined) {
        navigate('/');
        return;
      }
      if (email.length === 0) {
        notifyError('Veuillez entrer une adresse email');
        return;
      }
      if (password.length === 0) {
        notifyError('Veuillez entrer un mot de passe');
        return;
      }
      sharedApiCall(api, '/reset-password', {
        id,
        email,
        password,
      })
        .then(data => {
          setIsSubmitting(false);
          if (data.success) {
            setSession(data.session);
            navigate(postLoginPage);
          } else {
            notifyError('Échec de la réinitialisation du mot de passe');
          }
        })
        .catch(err => {
          setIsSubmitting(false);
          notifyError(err);
        });
    },
    [id, api, navigate, postLoginPage]
  );

  // Redirect to home if user is already logged in
  // TODO: do it for all pages in a middleware
  useEffect(() => {
    if ('sessionId' in session) {
      navigate('/', {replace: true});
    }
  }, [navigate, session]);

  if (whiteLabeling?.auth?.type === HoobiizWhiteLabelingAuthType.ModeHero) {
    const whiteLabelingModeHero = whiteLabeling.auth as ModeHeroAuthWhiteLabeling;
    return (
      <ResetPasswordPageModeHero
        whiteLabeling={whiteLabelingModeHero}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handlePasswordResetSubmit={handlePasswordResetSubmit}
        isSubmitting={isSubmitting}
      />
    );
  }

  return (
    <ResetPasswordPageModeDefault
      wrapper={wrapper}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      handlePasswordResetSubmit={handlePasswordResetSubmit}
      isSubmitting={isSubmitting}
    />
  );
};
ResetPasswordPage.displayName = 'ResetPasswordPage';
