import {FC, isValidElement} from 'react';

import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {SanitizedItem} from '@shared/model/search_tables';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {EmptyFragment} from '@shared-frontend/lib/react';

interface AppLogoProps {
  logo: FrontendTheme['main']['logo'];
}

export const AppLogo: FC<AppLogoProps> = props => {
  const {logo} = props;
  if (!logo) {
    return EmptyFragment;
  }
  if (isValidElement(logo)) {
    return logo;
  }
  return <HoobiizMediaView media={logo as SanitizedItem<'HoobiizMedia'>} width="auto" />;
};

AppLogo.displayName = 'AppLogo';
