import {
  HoobiizActivityId,
  HoobiizCartItemId,
  HoobiizCat1Id,
  HoobiizCat2Id,
  HoobiizCat3Id,
  HoobiizExpertTicketInfoId,
  HoobiizExpertTicketStockId,
  HoobiizMediaId,
  HoobiizOfferId,
  HoobiizOrderItemId,
  HoobiizStockBatchId,
  HoobiizStockEntryId,
  HoobiizStockId,
  HoobiizStockWeeklyTemplateId,
  HoobiizTicketFileId,
  HoobiizTicketInfoId,
  HoobiizTicketInfoOptionId,
  HoobiizUserGroupId,
  HoobiizVendorId,
} from '@shared/dynamo_model';
import {smallUidUnsafe} from '@shared/lib/rand';

// HoobiizMediaId
const mediaIdPrefix = 'm-';
export function isMediaId(str: string): str is HoobiizMediaId {
  return str.startsWith(mediaIdPrefix);
}
export function hoobiizMediaId(): HoobiizMediaId {
  return smallUidUnsafe(mediaIdPrefix) as HoobiizMediaId;
}

// HoobiizVendorId
const vendorIdPrefix = 'v-';
export function isVendorId(str: string): str is HoobiizVendorId {
  return str.startsWith(vendorIdPrefix);
}
export function hoobiizVendorId(): HoobiizVendorId {
  return smallUidUnsafe(vendorIdPrefix) as HoobiizVendorId;
}

// HoobiizActivityId
const activityIdPrefix = 'a-';
export function isActivityId(str: string): str is HoobiizActivityId {
  return str.startsWith(activityIdPrefix);
}
export function hoobiizActivityId(): HoobiizActivityId {
  return smallUidUnsafe(activityIdPrefix) as HoobiizActivityId;
}

// HoobiizCat1Id
const cat1IdPrefix = 'c1-';
export function isCat1Id(str: string): str is HoobiizCat1Id {
  return str.startsWith(cat1IdPrefix);
}
export function hoobiizCat1Id(): HoobiizCat1Id {
  return smallUidUnsafe(cat1IdPrefix) as HoobiizCat1Id;
}

// HoobiizCat2Id
const cat2IdPrefix = 'c2-';
export function isCat2Id(str: string): str is HoobiizCat2Id {
  return str.startsWith(cat2IdPrefix);
}
export function hoobiizCat2Id(): HoobiizCat2Id {
  return smallUidUnsafe(cat2IdPrefix) as HoobiizCat2Id;
}

// HoobiizCat3Id
const cat3IdPrefix = 'c3-';
export function isCat3Id(str: string): str is HoobiizCat3Id {
  return str.startsWith(cat3IdPrefix);
}
export function hoobiizCat3Id(): HoobiizCat3Id {
  return smallUidUnsafe(cat3IdPrefix) as HoobiizCat3Id;
}

// HoobiizTicketInfoId
const ticketInfoIdPrefix = 'ti-';
export function isTicketInfoId(str: string): str is HoobiizTicketInfoId {
  return str.startsWith(ticketInfoIdPrefix);
}
export function hoobiizTicketInfoId(): HoobiizTicketInfoId {
  return smallUidUnsafe(ticketInfoIdPrefix) as HoobiizTicketInfoId;
}

// HoobiizExpertTicketInfoId
const expertTicketInfoIdPrefix = 'eti-';
export function isExpertTicketInfoId(str: string): str is HoobiizExpertTicketInfoId {
  return str.startsWith(expertTicketInfoIdPrefix);
}
export function hoobiizExpertTicketInfoId(): HoobiizExpertTicketInfoId {
  return smallUidUnsafe(expertTicketInfoIdPrefix) as HoobiizExpertTicketInfoId;
}

// HoobiizTicketInfoOptionId
const ticketInfoOptionIdPrefix = 'tio-';
export function isTicketInfoOptionId(str: string): str is HoobiizTicketInfoOptionId {
  return str.startsWith(ticketInfoOptionIdPrefix);
}
export function hoobiizTicketInfoOptionId(): HoobiizTicketInfoOptionId {
  return smallUidUnsafe(ticketInfoOptionIdPrefix) as HoobiizTicketInfoOptionId;
}

// HoobiizTicketFileId
const ticketFileIdPrefix = 'tf-';
export function isTicketFileId(str: string): str is HoobiizTicketFileId {
  return str.startsWith(ticketFileIdPrefix);
}
export function hoobiizTicketFileId(): HoobiizTicketFileId {
  return smallUidUnsafe(ticketFileIdPrefix) as HoobiizTicketFileId;
}

// HoobiizStockWeeklyTemplateId
const stockWeeklyTemplateIdPrefix = 'swt-';
export function isStockWeeklyTemplateId(str: string): str is HoobiizStockWeeklyTemplateId {
  return str.startsWith(stockWeeklyTemplateIdPrefix);
}
export function hoobiizStockWeeklyTemplateId(): HoobiizStockWeeklyTemplateId {
  return smallUidUnsafe(stockWeeklyTemplateIdPrefix) as HoobiizStockWeeklyTemplateId;
}

// HoobiizExpertTicketStockId
const expertTicketStockIdPrefix = 'etsi-';
export function isExpertTicketStockId(str: string): str is HoobiizExpertTicketStockId {
  return str.startsWith(expertTicketStockIdPrefix);
}
export function hoobiizExpertTicketStockId(): HoobiizExpertTicketStockId {
  return smallUidUnsafe(expertTicketStockIdPrefix) as HoobiizExpertTicketStockId;
}

// HoobiizStockId
const stockIdPrefix = 'si-';
export const virtualStockIdSuffix = '-virtual';
export function isStockId(str: string): str is HoobiizStockId {
  return str.startsWith(stockIdPrefix);
}
export function hoobiizStockId(): HoobiizStockId {
  return smallUidUnsafe(stockIdPrefix) as HoobiizStockId;
}
export function hoobiizStockIdFromTemplate(
  templateId: HoobiizStockWeeklyTemplateId,
  startTs: number
): HoobiizStockId {
  return `${stockIdPrefix}${templateId.slice(
    stockWeeklyTemplateIdPrefix.length
  )}-${startTs}` as HoobiizStockId;
}
export function hoobiizVirtualStockIdFromTemplate(
  templateId: HoobiizStockWeeklyTemplateId,
  startTs: number
): HoobiizStockId {
  return `${hoobiizStockIdFromTemplate(
    templateId,
    startTs
  )}${virtualStockIdSuffix}` as HoobiizStockId;
}

export interface NormalStockIdInfo {
  id: HoobiizStockId;
  fromTemplate: false;
}
export interface TemplateStockIdInfo {
  id: HoobiizStockWeeklyTemplateId;
  fromTemplate: true;
  stockWeeklyTemplateId: HoobiizStockWeeklyTemplateId;
  startTs: number;
  isVirtual: boolean;
}
export type StockIdInfo = NormalStockIdInfo | TemplateStockIdInfo;
export const isNormalStockId = (val: StockIdInfo): val is NormalStockIdInfo => !val.fromTemplate;
export const isTemplateStockId = (val: StockIdInfo): val is TemplateStockIdInfo => val.fromTemplate;
export function getStockIdInfo(id: HoobiizStockId | HoobiizStockWeeklyTemplateId): StockIdInfo {
  const r = new RegExp(
    `^${stockIdPrefix}(?<templateId>[a-z0-9]{6})-(?<startTs>[0-9]{13})(?<suffix>${virtualStockIdSuffix})?$`,
    'gu'
  );
  const match = r.exec(id);
  const {templateId, startTs, suffix} = match?.groups ?? {};
  if (templateId === undefined || startTs === undefined) {
    return {id: id as HoobiizStockId, fromTemplate: false};
  }
  return {
    id: id as HoobiizStockWeeklyTemplateId,
    fromTemplate: true,
    isVirtual: suffix !== undefined,
    stockWeeklyTemplateId:
      `${stockWeeklyTemplateIdPrefix}${templateId}` as HoobiizStockWeeklyTemplateId,
    startTs: parseFloat(startTs),
  };
}

// HoobiizStockEntryId
const stockEntryIdPrefix = 'se-';
export function isStockEntryId(str: string): str is HoobiizStockEntryId {
  return str.startsWith(stockEntryIdPrefix);
}
export function hoobiizStockEntryId(): HoobiizStockEntryId {
  return smallUidUnsafe(stockEntryIdPrefix) as HoobiizStockEntryId;
}

// HoobiizStockBatchId
const stockBatchIdPrefix = 'sb-';
export function isStockBatchId(str: string): str is HoobiizStockBatchId {
  return str.startsWith(stockBatchIdPrefix);
}
export function hoobiizStockBatchId(): HoobiizStockBatchId {
  return smallUidUnsafe(stockBatchIdPrefix) as HoobiizStockBatchId;
}

// HoobiizOfferId
const offerIdPrefix = 'o-';
export function isOfferId(str: string): str is HoobiizOfferId {
  return str.startsWith(offerIdPrefix);
}
export function hoobiizOfferId(): HoobiizOfferId {
  return smallUidUnsafe(offerIdPrefix) as HoobiizOfferId;
}

// HoobiizCartItemId
const cartItemIdPrefix = 'ci-';
export function isCartItemId(str: string): str is HoobiizCartItemId {
  return str.startsWith(cartItemIdPrefix);
}
export function hoobiizCartItemId(): HoobiizCartItemId {
  return smallUidUnsafe(cartItemIdPrefix) as HoobiizCartItemId;
}

// HoobiizOrderId
export const NO_ORDER_ITEM_ID = 'no-order' as HoobiizOrderItemId;
const orderIdPrefix = 'oi-';
export function isOrderItemId(str: string): str is HoobiizOrderItemId {
  return str.startsWith(orderIdPrefix);
}
export function hoobiizOrderItemId(): HoobiizOrderItemId {
  return smallUidUnsafe(orderIdPrefix) as HoobiizOrderItemId;
}

// HoobiizUserGroupId
const userGroupPrefix = 'ug-';
export function isHoobiizUserGroupId(str?: string): str is HoobiizUserGroupId {
  return str !== undefined && str.startsWith(userGroupPrefix);
}
export function hoobiizUserGroupId(val?: string): HoobiizUserGroupId {
  return (
    val === undefined ? smallUidUnsafe(userGroupPrefix) : `${userGroupPrefix}${val}`
  ) as HoobiizUserGroupId;
}
